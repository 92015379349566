<template>
  <rb-search-layout>

    <template #searchbar>
      <v-search-bar search class="min-w-min">
        <template #suggest>
          <div class="flex justify-between items-center px-5">
            <div class="flex flex-grow items-center gap-2 py-2 overflow-hidden truncate  w-full">
              <div class="bg-gray-600 text-white px-2 rounded-lg font-sans font-semibold shadow-sm" v-for="(sg, x) in searchsuggestiontags.slice(0, 3)" :key="x">
                  <span>{{sg.description ? sg.description : ''}}</span>
              </div>
            </div>
            <div class="py-2">
                <rb-filter></rb-filter>
            </div>
          </div>
        </template>
      </v-search-bar>
    </template>
    <template #searchresult>
      <rb-search-result :searchresult="searchresult" :query="query" />
     
    </template>
    <template #map>
      <template>
        <l-map class="sm:h-60 md:h-full" style="min-height: 450px; z-index: 1;" :center="current" :options="LSearchMapOptions" ref="lmapsearch">
          <l-marker :lat-lng="mark.location" v-for="(mark, i) in searchlocations" :key="i" :icon="LMarketIcon">
            <l-popup >
                <v-card
                :color="'white'"
                class="ma-4"
                height="120"
                width="250"
                elevation="0"
              >
                <div class="w-full h-1/2 bg-white bg-cover bg-center" :style="`background-image: url('`+mark.store.CoverPhoto+`')`">
                  <div class="flex justify-between items-center px-2 py-1">
                    <div class="bg-white px-1 shadow-md rounded-sm text-center w-min">
                      <small class="font-sans tracking-wide text-center text-gray-500 font-medium">{{mark.store.business_line ? mark.store.business_line : ''}}</small>
                    </div>
                  </div>
                </div>
                <div class="grid mt-1">
                    <span class="text-gray-500 font-sans tracking-wide font-bold focus:text-white">{{mark.store.name ? mark.store.name : ''}}</span>
                    <span class="text-gray-400 font-sans tracking-wide font-medium">{{'$350.00 - $2,300.00'}}</span>
                </div>
                </v-card>
            </l-popup>
          </l-marker>
          <l-tile-layer :url="LSearchMapOptions.url" :attribution="LSearchMapOptions.attribution"></l-tile-layer>
        </l-map>
      </template>
    </template>
  </rb-search-layout>
</template>

<script>
import {  mapGetters } from "vuex";
import { latLng } from "leaflet";
import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import searchEngineService from '../../services/searchengine/searchengine.service';
import Search from '../../models/entity/search.model';
import { LMarketIcon, LSearchMapOptions } from '../../utils/maps/maputilities';
import RBFilterVue from "./components/RBFilter.vue";
import RBResult from "./components/RBResult.vue";
import RBSearchLayout from "./components/RBSearchLayout.vue";



export default {
  components: {
    'rb-filter': RBFilterVue,
    'rb-search-result': RBResult,
    'rb-search-layout': RBSearchLayout,
  },
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      search_data: new Search(),
      WindowHeight:'',
      WindowWidth:'',
      searchresult:[],
      CoverPhoto:require('@/assets/home/bgcontent.png'),
      searchsuggestiontags:[],
      LSearchMapOptions,
      LMarketIcon
    }
  },
  mounted(){

    this.init();
    window.onresize = () => {

        this.WindowWidth = document.documentElement.clientWidth;
        this.WindowHeight = document.documentElement.clientHeight;
     };
  },
  methods:{

    init: async function() {
      await this.onSearch(this.query)
      await this.getSuggestionsTags();
    },

    goTo: function (ruta){
       location.href = '/' + ruta
    },
    onSearch: async function(query) {
        this.search_data.search = query;
        let response = await EntityService.search(this.search_data);
        if(response.success) {
            this.searchresult = response.data.map((e) => {return {...e, tags: e.tags.split(',')}});
        }
      
    },
    
    winWidth: function () {

      this.WindowWidth = screen.width;
      this.WindowHeight = screen.height;

    },

    getSuggestionsTags: async function() {
      let response = await searchEngineService.getSuggestionTags();
      if(response.success) {
        this.searchsuggestiontags = response.data;
      }
    },
  },
  computed: {

    suggestiontags: function() {
      return [...new Set(this.searchsuggestiontags.map(item => item.description))]
    },
     ...mapGetters({
      currentlocation: "geolocation/currentlocation",
    }),

    current: function() {
      return latLng(this.currentlocation.lat, this.currentlocation.long)
    },
    searchlocations: function() {
        let locations = this.searchresult.filter(item => item.latitude && item.length).map((x) => {return {location: latLng(x.latitude, x.length), store: {...x} }});
        return locations ? locations : [];
    }

  },
  watch: {
     query: function() {
       if(this.query) {
         this.onSearch(this.query);
       }
     },
     searchlocations: function() {
      if(this.searchlocations) {
        this.$refs.lmapsearch.mapObject.fitBounds(this.searchlocations.map(x => {return [x.location.lat, x.location.lng]}));
      }
     }
  }
}
</script>

<style scoped>
</style>