<template>
  <button class="bg-fucsia-lighter text-white font-sans tracking-wide rounded-sm shadow-sm hover:bg-fucsia-dark" :class="buttonClass">
    <slot name="name">name</slot>
  </button>
  
</template>

<script>
export default {
    props: {
       small: Boolean,
       large: Boolean
    },
    computed: {
        buttonClass() {
            return {
                'px-1 py-0': this.small,
                'px-2 py-1': this.large
            }
        }
    }
    
}
</script>

<style>

</style>