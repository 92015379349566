<template>
  <v-menu
    bottom
    origin="center center"
    transition="slide-y-transition"
    v-model="isFilter"
    offset-y
    left
    :close-on-content-click="false"
    nudge-bottom="20"
    offset-overflow
    return-value
    ref="filtermenu"
    >
    <template v-slot:activator="{ on, attrs }">
    <div v-on="on" v-bind="attrs">
        <button class="flex items-center text-sm text-gray-500 gap-1 bg-gray-200 px-3 py-1 rounded-xl font-sans tracking-wide hover:bg-gray-300 hover:shadow-sm">
        <f-icon class="font-light" :icon="['fas', 'sliders-h']"></f-icon>
        <span>Filtros</span>
        <f-icon class="font-light text-gray-700" :icon="['fas', 'chevron-down']"></f-icon>
        </button>
    </div>
    </template>
    <v-card class="w-full">
    <v-card-text>
        <div class="grid gap-y-2">
        <div>
            <span class="font-sans text-lg font-semibold tracking-wide">Tipo de Negocio</span>
            <v-chip-group
            v-model="filter.business_line"
            column
            multiple
            color="blue-grey darken-4"
            >
            <v-chip
            outlined
            v-for="(line, x) in business_line"
            :key="x"
            >
            {{line.label ? line.label : ''}}
            </v-chip>
            </v-chip-group>
        </div>
        <div>
            <span class="font-sans text-lg font-semibold tracking-wide">Rango de Precio</span>
            <div class="grid md:flex justify-between items-center gap-5">
            <rb-input-rounded v-model="filter.price.min" placeholder="Precio Min." />
            <rb-input-rounded v-model="filter.price.max" placeholder="Precio Max." />
            </div>
        </div>
        <div>
            <span class="font-sans text-lg font-semibold tracking-wide">Valoraciones</span>
            <v-rating
                background-color="orange lighten-3"
                color="orange"
                :length="5"
                size="25"
                :value="0"
                v-model="filter.score"
            ></v-rating>
        </div>
        </div>
    </v-card-text>
    <v-card-actions class="flex justify-end items-center gap-2">

        <rb-gray-button small elevationsm class="px-2" @click.native="isFilter = false">
        <template #name>
            Cancelar
        </template>
        </rb-gray-button>
        
        <rb-pink-button small elevationsm class="px-2">
        <template #name>
            Aplicar
        </template>
        </rb-pink-button>
    </v-card-actions>
    </v-card>
</v-menu>
</template>

<script>
import RBRounded from '../../../@core/components/form/input/RBInputRounded.vue';
import RBPinkButton from '../../../@core/components/form/button/RBButtonPink.vue';
import RBGrayButton from '../../../@core/components/form/button/RBButtonGray.vue';

export default {
    data: function() {
        return {
            filter: {
            price: {
            min: '',
            max: ''
            },
            business_line: [],
            score: 0
            },
            isFilter: false,
            business_line: [
                {id: '1', label: 'Mexicana'},
                {id: '2', label: 'Psicología'},
                {id: '3', label: 'Japonesa'},
                {id: '4', label: 'Antojitos'},
            ]
        }
    },
    components: {
        'rb-input-rounded': RBRounded,
        'rb-pink-button': RBPinkButton,
        'rb-gray-button': RBGrayButton,
    }
}
</script>

<style>

</style>