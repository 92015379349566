<template>
<div class="py-5 px-5 max-h-full overflow-auto" style="min-height: 80vh;">
    <div class="grid  grid-cols-1 md:grid-cols-6 lg:grid-cols-12  gap-y-5 h-auto max-h-full " style="min-height: 80vh;">

      <div class="sm:col-start-1 sm:col-end-1 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-5  
          bg-white shadow-sm sm:rounded-tl-md sm:rounded-tr-md md:rounded-tl-md md:rounded-bl-md px-5 py-3 h-auto">
          <div class="grid gap-y-3">

            <slot name="searchbar"></slot>
            <slot name="searchresult"></slot>

          </div>

      </div>
       <div class="sm:col-start-1 sm:col-end-1 md:col-start-1 md:col-end-7 lg:col-start-5 lg:col-end-13 bg-white shadow-sm mb-20 md:mb-0">

        <slot name="map"></slot>

      </div>


    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>