<template>
  <input :type="type" class="bg-gray-50 rounded-xl border border-solid border-gray-200 px-3 min-w-min py-1 shadow-sm focus:outline-none focus:ring-gray-200 focus:ring-1">
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        }
    }
}
</script>

<style>

</style>