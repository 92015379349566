import L from 'leaflet';
import { latLng } from "leaflet";

export const LMarketIcon = L.icon(
    {
    iconUrl: '/img/maps/pin.svg',
    shadowSize:   [50, 64],
    shadowAnchor: [4, 62],
    iconSize: [35, 45],
    iconAnchor: [19, 45],
    popupAnchor:  [-3, -37]
  }
)

export const LSearchMapOptions = {
        zoom: 7.5,
        center: latLng(47.41322, -1.219482),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        currentCenter:  latLng(47.41322, -1.219482),
}