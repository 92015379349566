<template>
  <button class="bg-gray-200 text-gray-600 font-sans tracking-wide rounded-sm shadow-sm hover:bg-gray-300" :class="classes" >
    <slot name="name">name</slot>
  </button>
</template>

<script>
import sizeable from '../../../mixins/sizeable';
import elevatable from '../../../mixins/elevatable';

export default {
  mixins: [sizeable, elevatable],
  computed: {
    classes() {return {...this.sizeableClass, ...this.elevationClass}},
  }
}
</script>

<style>

</style>
