<template>
  <div class="grid gap-y-2">
    <div class="flex justify-between items-center">
        <div class="flex items-baseline gap-3">
        <span class="font-sans font-bold tracking-wide text-gray-600  text-3xl">{{query}}</span>
        <span class="font-sans text-gray-400 font-semibold tracking-wider">{{searchresult ? searchresult.length : 0}} resultados </span>
        </div>
        <div class="flex items-center gap-2 justify-end">
        <button class="group bg-pink-100 px-3 py-1 rounded-md hover:bg-pink-500">
            <f-icon class="text-lg text-pink-500 group-hover:text-white" :icon="['fas', 'caret-left']"></f-icon>
        </button>
        <button  class="group bg-pink-100 px-3 py-1 rounded-md hover:bg-pink-500" >
            <f-icon class="text-lg text-pink-500 group-hover:text-white" :icon="['fas', 'caret-right']"></f-icon>
        </button>
        </div>
    </div>

    <div class="grid gap-y-5">
        <div class="w-full h-auto md:h-40 grid grid-cols-12 rounded-tl-md gap-5 gap-y-3" v-for="(result, i) in searchresult" :key="i">
        <div class="col-span-4 rounded-lg">
            <div class="bg-cover bg-center w-full h-full rounded-lg"  :style="`background-image: url('`+result.ProfilePicture+`')`"></div>
        </div>
        <div class="col-span-8">
            <div class="flex items-center">
            <div class="w-min bg-fucsia-lighter text-white font-sans text-sm px-2 rounded-sm shadow-sm font-semibold tracking-wide">
                <span>POPULAR</span>
            </div>
            </div>
            <div class="flex flex-wrap py-2">
            <span class="font-sans text-2xl tracking-wider">{{result.name ? result.name : ''}}</span>
            </div>
            <div class="py-2">
                <div class="grid grid-cols-3 divide-x divide-gray-400">
                    <small class="font-sans text-gray-400 font-semibold tracking-wide text-center truncate overflow-hidden"  v-for="(tag, x) in result.tags.slice(0, 3)" :key="x">{{tag ? tag : ''}}</small>
            </div>
            </div>
            <div class="sm:grid md:flex justify-between items-center py-2 gap-5 gap-y-1">
            <v-rating
                background-color="orange lighten-3"
                color="orange"
                readonly
                :length="5"
                size="25"
                :value="3"
            ></v-rating>
            <div>
                <span class="text-lg font-sans font-bold tracking-wide text-black">{{'250' | currency}} <span class="text-gray-400 font-light text-xs">/ MXN</span></span>
            </div>
            </div>
        </div>
        </div>
    </div>
    
    </div>
</template>

<script>
export default {
    props: {
        searchresult: {
            type: Array,
            default: function() {return []}
        },
        query: {
            type: String,
            default: ""
        }
    }

}
</script>

<style>

</style>